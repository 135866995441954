import type { DataObjectOptions, MasterDetailsDefinition, ItemModel } from './types.ts';
import type DataObject from './DataObject.ts';

import { dateUtils } from 'o365-utils';
import { getDataObjectById } from './store.ts';

export default class MasterDetails<T extends ItemModel = ItemModel> {
    /** Property for tracking if the DataObject master details is initialized. */
    private _initialized = false;
    /** The details DataObject */
    private _dataObject!: DataObject<T>;
    /**
     * Id of the details data object. 
     * This MasterDetails instance belongs to this DataObject
     */
    private _detailsId?: string;
    /**Id of the master data object of this MasterDetails instance */
    private _masterId?: string;
    /** Indicates that this DataObject is details */
    private _isSet: boolean = false;
    /** Details bindings definition */
    private _definition: MasterDetailsDefinition[] = [];
    /** Details DataObjects ids */
    private _detailsDataObjects: string[] = [];
    private _loadAllDetails = false;
    /**
     * Indicates that this MasterDetails instance is initialized 
     * and attached to the details DataObject
     */
    get isSet() { return this._isSet; }
    /** Master details bindings definition */
    get definition() { return this._definition; }

    /** Load all details that have rows loaded in the master DataObject  */
    get loadAllDetails() {
        return this._loadAllDetails;
    }
    set loadAllDetails(value: boolean) {
        this._loadAllDetails = value;
        if (value && !this.disableAutoLoad) {
            this.disableAutoLoad = true;
        }
    }
    /** Disable auto loading on master object index change  */
    disableAutoLoad = false;
    /** Disable loading the detail when master value is null */
    disableLoadOnNull = false;
    masterRowCurrentIndex: number | null = null;;
    /** When true will always set detail fields from master during saves */
    alwaysSetMasterValues = true;
 
    /**
     * Internal function used by DataObject, called only once.
     * @ignore
     */
    initialize(pOptions: DataObjectOptions<T>, pDataObject: DataObject<T>) {
        if (this._initialized) { return; }
        this._dataObject = pDataObject;
        this.disableLoadOnNull = pOptions.disableMasterDetailsNullValues ?? false;
        if (!!pOptions.masterDataObject_ID) {
            this.setMasterDetails(pOptions);
        }
        this._initialized = true;
    }

    /**
     * Set new master details bindings. When no master object id is provided 
     * and there are previously set bindings this will unset the master details. 
     */
    setMasterDetails(pOptions?: {
        masterDataObject_ID?: string;
        masterDetailDefinition?: MasterDetailsDefinition[];
        disableAutoLoad?: boolean;
    }) {
        if (pOptions?.masterDataObject_ID) {
            // Set new master details
            this._masterId = pOptions.masterDataObject_ID;
            this._detailsId = this._dataObject.id;
            if (!pOptions.masterDetailDefinition) {
                throw new Error(`Missing master details definition on ${this._detailsId}`);
            } else if (pOptions.masterDetailDefinition.length === 0 && pOptions.disableAutoLoad) {
                console.error(`${this._dataObject.id}: Misconfigured master details.`);
            }

            this.disableAutoLoad = pOptions.disableAutoLoad ?? false;

            if (!Array.isArray(pOptions.masterDetailDefinition)) {
                this._definition = JSON.parse(pOptions.masterDetailDefinition);
            } else {
                this._definition = pOptions.masterDetailDefinition;
            }

            this._definition.forEach(binding => {
                if (binding.operator == null) {
                    binding.operator = 'equals';
                }
            });

            this._isSet = true;
            const masterDataObject = this._getMasterDataObject()!;
            masterDataObject.masterDetails.pushDetailDataObject(this._dataObject.id);
            // Check if master DataObject is loaded and load self if options allow it
            if (masterDataObject.state.isLoaded && !this.disableAutoLoad) {
                this._dataObject.load();
            }

        } else if (this._isSet) {
            // Unset master details
            const masterDataObject = this._getMasterDataObject();
            if (masterDataObject) {
                const index = masterDataObject.masterDetails._detailsDataObjects.findIndex(x => x === this._detailsId);
                if (index !== -1) {
                    masterDataObject.masterDetails._detailsDataObjects.splice(index, 1);
                }
            }
            this._masterId = undefined;
            this._detailsId = undefined;
            this.disableAutoLoad = false;
            this._definition = [];
            this._isSet = false;
        }
    }

    /** Load all details of this master DataObject */
    loadDetailDataObjects() {
        const promises: Promise<void>[] = [];
        this._detailsDataObjects.forEach(detailsId => {
            const detailsDataObject = getDataObjectById(detailsId, this._dataObject.appId);
            if (!detailsDataObject.masterDetails.disableAutoLoad) {
                if (detailsDataObject.batchDataEnabled && detailsDataObject.batchData?.storage.hasChanges) {
                    promises.push(detailsDataObject.save().then(_ => {
                        detailsDataObject.load();
                        // this.loadAllDetails(detailsDataObject,vCurrentIndex);
                    }));
                } else {
                    promises.push(detailsDataObject.load());
                }
            }
        });

        if (promises.length) {
            Promise.all(promises).then(() => {
                this._dataObject.emit('AllDetailsLoaded');
            });
        }
    }

    /**
     * Clear all details of this master DataObject. This is used 
     * when unsetting the current index, or when master retreived no data
     */
    clearAllDetailDataObjects() {
        this._detailsDataObjects.forEach(detailsId => {
            const detailsDataObject = getDataObjectById(detailsId, this._dataObject.appId);
            detailsDataObject.storage.clearItems();
            if (detailsDataObject.hasDynamicLoading) {
                detailsDataObject.dynamicLoading.dataLoaded([], { skip: 0 });
            }
            detailsDataObject.unsetCurrentIndex();
        });
    }

    /** Get the masterDetailString for this details DataObject */
    getFilterString(pIndex?: number) {
        if (!this.isSet) { return null; }

        if (this.loadAllDetails) {
            const masterDataObject = this._getMasterDataObject();
            if (masterDataObject == null) { return null; }
            const filterStrings: string[] = [];
            masterDataObject.storage.data.forEach(item => {
                const filter = this._getFilterString(item.index);
                if (filter && filter !== 'null') {
                    filterStrings.push(filter);
                }
            });
            return filterStrings.join(' OR ');
        } else {
            return this._getFilterString(pIndex);
        }

    }

    /** Register a DataObject as a detail of this master DataObject */
    pushDetailDataObject(pDetailsId: string) {
        if (this._detailsDataObjects.includes(pDetailsId)) {
            throw new Error(`${this._dataObject.id} already has ${pDetailsId} registered as a details DataObject`);
        }
        this._detailsDataObjects.push(pDetailsId);
    }

    removeFromMaaster() {
        if (this.isSet) {
            const masterDataObject = this._getMasterDataObject();
            if (masterDataObject) {
                masterDataObject.masterDetails.removeDetailDataObject(this._dataObject.id);
            }
        }
    }

    /** Remove a DataObject from details list */
    removeDetailDataObject(pDetailsId: string) {
        const index = this._detailsDataObjects.findIndex(x => x == pDetailsId);
        if (index !== -1) {
            this._detailsDataObjects.splice(index, 1);
        }
    }

    /** TODO(Augustas): Add description */
    async resolveMasterRow(pIndex?: number | null) {
        if (pIndex === null || this._masterId == null) {
            return Promise.resolve(false);
        } else {
            const masterDataObject = this._getMasterDataObject()!;
            const masterRow = pIndex === undefined
                ? masterDataObject.current
                : masterDataObject.data[pIndex];
            return masterRow?.loadingPromise ?? Promise.resolve(false);
        }
    }

    /** Get values from master row for inserting new details rows */
    getMasterDetailRowForInsert() {
        const returnValues: Record<string, any> = {};
        if (this.definition != null) {
            const masterDataObject = this._getMasterDataObject()!;
            if (masterDataObject.current != null) {
                this.definition.forEach(binding => {
                    returnValues[binding.detailField] = masterDataObject.current![binding.masterField];
                });
            }
        }
        return returnValues;
    }

    getMasterRowIndex() {
        const masterDataObject = this._getMasterDataObject()!;
        if (masterDataObject && masterDataObject.current != null) {
            return masterDataObject.current.index;
        }
        return null;
    }

    /** Update master default values for bound fields */
    setRowFieldDefaults() {
        if (this.definition != null) {
            const masterDataObject = this._getMasterDataObject()!;
            if (masterDataObject && masterDataObject.current != null) {
                this.definition.forEach(binding => {
                    if (this._dataObject.fields[binding.detailField]) {
                        this._dataObject.fields[binding.detailField]!.masterRowValue = masterDataObject.current![binding.masterField];
                    }
                });
                this.masterRowCurrentIndex = masterDataObject.current.index;
            }
        }
    }

    resetMasterDataObjectIndexOnError(pSavedRow: any) {
        if (this.definition == null) return;
        const masterDataObject = this._getMasterDataObject()!;
        if (masterDataObject) {
            if (pSavedRow.masterRowIndex >= 0 && masterDataObject.current.index !== pSavedRow.masterRowIndex) {
                window.setTimeout(() => {
                    masterDataObject.setCurrentIndex(pSavedRow.masterRowIndex);
                }, 500)

            }
        }
    }

    getFilterObject(pIndex?: number) {
        if (!this.isSet) { return null; }

        const masterDataObject = this._getMasterDataObject()!;
        const filterItems: {
            column: string,
            valueType: 'string',
            type: 'expression',
            operator: string,
            value: any
        }[] = [];
        const masterRow = (pIndex == null ? masterDataObject.current : masterDataObject.data[pIndex])!; // TODO(Augustas): Handle master rows that don't exist
        this.definition.forEach(binding => {
            let masterValue = masterRow[binding.masterField];
            filterItems.push({
                column: binding.detailField,
                valueType: 'string',
                type: 'expression',
                // @ts-ignore Some old compatibility 
                operator: binding.operator === 'startswith' ? 'beginswith' : binding.operator,
                value: masterValue
            });
        });

        return {
            items: filterItems,
            mode: 'and',
            type: 'group'
        };
    }

    getDetailsOptions() {
        const appId = this._dataObject.appId;
        return this._detailsDataObjects.map(detail => {
            return {
                id: detail,
                definition: [...getDataObjectById(detail, appId).masterDetails.definition]
            }
        });
    }

    /** Get the master DataObject */
    private _getMasterDataObject(): DataObject<ItemModel> | null {
        return this._masterId
            ? getDataObjectById(this._masterId, this._dataObject.appId)
            : null;
    }

    /** Default masterDetailString constructor */
    private _getFilterString(pIndex?: number) {
        if (!this.isSet) { return null; }

        const masterDataObject = this._getMasterDataObject()!;
        const bindings: string[] = [];
        const masterRow = (pIndex == null ? masterDataObject.current : masterDataObject.data[pIndex])!; // TODO(Augustas): Handle master rows that don't exist
        this.definition.forEach(binding => {
            let boundValue: string;
            let masterValue = masterRow[binding.masterField];
            if (masterValue?.constructor === Date) {
                masterValue = dateUtils.format(masterValue, 'yyyy-MM-dd');
            }
            if (masterValue == null) {
                if (this.disableLoadOnNull) {
                    bindings.push('1=0');
                } else {
                    bindings.push(`[${binding.detailField}] IS NULL`);
                }
                // bindings.push(`ISNULL([${binding.detailField}], '') = ''`);
            } else {
                switch (binding.operator.toLowerCase()) {
                    // @ts-ignore Allow the fallthrough case as it is used for warning
                    case 'startswith':
                        console.warn(`Out of spec master details binding found for ${this._detailsId}. 
                        Operrator for binding between ${binding.detailField} and ${binding.masterField} should be beginswith instead of startswith`);
                    case 'beginswith':
                        boundValue = `LIKE '${masterValue}%'`;
                        break;
                    case 'endswith':
                        boundValue = `LIKE '%${masterValue}'`;
                        break;
                    case 'contains':
                        boundValue = `LIKE '%${masterValue}%'`;
                        break;
                    default:
                        boundValue = `= '${masterValue}'`;
                        break;
                }
                bindings.push(`[${binding.detailField}] ${boundValue}`);
            }
        });

        return bindings.join(' AND ');
    }
}
